@import 'assets/colors.scss';
*{
	box-sizing: border-box;
}
body {
	font-family:"Lato", Arial, Helvetica, sans-serif;
	 width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $layout;
}
::-webkit-scrollbar {
	overflow-y: scroll;
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb{
	background: $light-color;
	border-radius: 8px;
}
a {
	text-decoration: none;
}
img{
	max-width: 100%;
}
.layout{
	display: flex;
	flex-direction: column;
	height: 100vh;
	margin: 0;
	background-color: $layout;
}
.main-container {
	margin: auto;
	width: 100%;
	background-color: $layout;
}
.Toastify__toast--success {
    border-radius: 6px;
    border: 1px solid #12b897;
    background:#f0fffe; 
    color: #0a987c;
    font-size: 14px;
    font-weight: 400;
}
.Toastify__toast--error {
    border-radius: 6px;
    border: 1px solid var(--semantic-sb-red-200-ffa-29-b, #ffa29b);
    background: var(--semantic-sb-red-100-ffeae-6, #ffeae6);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    color: var(--semantic-sb-red-cc-3027, #CC3027);
    font-size: 14px;
    font-weight: 400;
}
.lato-thin {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: normal;
  }
  
  .lato-light {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: normal;
  }
  
  .lato-regular {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
  }
  
  .lato-bold {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: normal;
  }
  
  .lato-black {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: normal;
  }
  
  .lato-thin-italic {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: italic;
  }
  
  .lato-light-italic {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: italic;
  }
  
  .lato-regular-italic {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: italic;
  }
  
  .lato-bold-italic {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: italic;
  }
  
  .lato-black-italic {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: italic;
  }

.container-ydp{
	max-width: 1282px;
	padding: 0 1rem;
	margin: auto;
}

.ant-btn {
	box-shadow: none;
}

.ant-btn-background-ghost {
	background: #fff !important;
	border-width: 2px;
}
  
@media (max-width: 768px) {
	.main-container {
			padding: 1rem;
			background-color: $layout;
		}
}
@media (max-width: 430px) {
	.main-container {
			padding: 1rem;
			background-color: $layout;
		}
}